import { trackEvent } from '@centered/analytics'
import PauseIcon from '@centered/components/dist/icons/PauseIcon'
import PlayIcon from '@centered/components/dist/icons/PlayIcon'
import Image from 'next/image'
import { useRef, useState } from 'react'
import { CoachView, decorateAudioUrl } from './coaches'

type Message = {
  coach: CoachView
  text: string
  audioSegment: string
}

interface SampleCoachMessageProps {
  messages: Message[]
  module: string
}

export default function SampleCoachMessage({
  messages,
  module,
}: SampleCoachMessageProps): JSX.Element {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  const [currentMessage, setCurrentMessage] = useState(
    messages[Math.floor(Math.random() * messages.length)],
  )
  const animationRef = useRef<number>()

  return (
    <div
      onClick={() => {
        trackEvent('Sampled home page audio', {
          type: 'module',
          module,
        })

        if (isPlaying) {
          audioRef.current?.pause()
        } else {
          audioRef.current?.play()
        }
      }}
      className="box cursor-pointer bg-gradient-to-r relative from-v5-pink to-v5-red rounded-full p-2 overflow-hidden flex items-center gap-2"
    >
      <style jsx>{`
        .box {
          height: 61.59;
        }

        @media (min-width: 768px) {
          .box {
            height: 76;
          }
        }
      `}</style>

      <audio
        hidden
        ref={audioRef}
        src={decorateAudioUrl(currentMessage.audioSegment)}
        onPlay={() => {
          setIsPlaying(true)

          function updateTime() {
            if (audioRef.current) {
              const audio = audioRef.current
              const progress = audio.currentTime / audio.duration

              setProgress(progress)
            }

            animationRef.current = requestAnimationFrame(updateTime)
          }

          animationRef.current = requestAnimationFrame(updateTime)
        }}
        onPause={() => setIsPlaying(false)}
        onTimeUpdate={() => {
          if (audioRef.current && audioRef.current.duration) {
            setProgress(
              audioRef.current.currentTime / audioRef.current.duration,
            )
          }
        }}
        onEnded={() => {
          setProgress(1)
          setIsPlaying(false)

          if (animationRef.current) {
            cancelAnimationFrame(animationRef.current)
          }

          setProgress(0)

          const currentMessageIndex = messages.findIndex(
            (message) => message.audioSegment === currentMessage.audioSegment,
          )

          const nextMessageIndex =
            currentMessageIndex === messages.length - 1
              ? 0
              : currentMessageIndex + 1

          setCurrentMessage(messages[nextMessageIndex])
        }}
      />

      <div
        style={{
          width: progress * 100 + '%',
          zIndex: 1,
        }}
        className="absolute bg-black bg-opacity-20 w-20 top-0 bottom-0 left-0 rounded-full"
      />

      <Image
        src={currentMessage.coach.avatar}
        alt={currentMessage.coach.name}
        className="rounded-full relative z-10 w-10 h-10 md:w-14 md:h-14"
      />

      <div className="flex-1 pr-2 md:pr-8 relative z-10 text-xs md:text-sm">
        <div className="font-semibold">{currentMessage.coach.name}</div>

        <p
          style={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
          }}
          className="select-none"
        >
          {currentMessage.text}
        </p>
      </div>

      <div className="w-5 h-5 md:w-14 md:h-14 absolute md:relative md:left-auto md:bottom-auto left-8 bottom-2 z-10 flex items-center justify-center bg-white text-v5-red rounded-full text-xs md:text-2xl">
        {isPlaying ? <PauseIcon /> : <PlayIcon solid className="ml-0.5" />}
      </div>
    </div>
  )
}
