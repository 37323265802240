import Image from 'next/image'
import Wrapper from '../shared/Wrapper'
import SampleCoachMessage from './SampleCoachMessage'
import DrinkingWaterImage from './assets/water-drink.jpg'
import { coaches } from './coaches'

export default function Burnout(): JSX.Element {
  return (
    <Wrapper>
      <div className="flex flex-col-reverse md:flex-row md:items-center gap-14">
        <div className="relative ml-4">
          <div className="absolute -left-4 -bottom-8">
            <SampleCoachMessage messages={messages} module="burn out" />
          </div>

          <Image
            src={DrinkingWaterImage}
            alt="female knowledge worker taking a break from focusing"
            className="rounded-xl"
            width={480}
            height={440}
            style={{
              borderRadius: 32,
            }}
          />
        </div>

        <div className="">
          <h2 className="text-2xl md:text-4xl font-bold leading-normal mb-10">
            I get burned out...
          </h2>

          <p className="text-lg md:text-2xl">
            Your Coach will encourage you to take <br /> 
            healthy breaks, drink water and <br /> 
            not work too long in one sitting.
          </p>
        </div>
      </div>
    </Wrapper>
  )
}

const messages = [
  {
    coach: coaches.nir,
    text: 'Take a few deep breaths and focus your mind.  Remember, you are Indistractable.',
    audioSegment: 'NIR_Break_028',
  },
  {
    coach: coaches.chris,
    text: "Hydration is key, drink some water, rest your eyes and then let's get back to the next Task. ",
    audioSegment: 'CM_Break_028',
  },
  {
    coach: coaches.brian,
    text: 'Your break is coming up.  Begin to come out of Flow.',
    audioSegment: 'BM_Break_017',
  },
  {
    coach: coaches.alex,
    text: 'If you were thinking about a bio break or getting a snack or something, now would be a good time to do that before your next meeting.',
    audioSegment: 'AR_MeetingNotify_007',
  },
  {
    coach: coaches.jason,
    text: 'You got a break coming up - get ready to go pencils down here.',
    audioSegment: 'JasonLangsdorf_Break_017',
  },
  {
    coach: coaches.kent,
    text: 'Breaks are an awesome way to take your mind off your work for a moment.  Now, enjoy your break!',
    audioSegment: 'RMX_Break_022',
  },
  {
    coach: coaches.jo,
    text: "Ok so, let's take a quick little break here; take a couple of deep inhales in through your nose.  Open mouth exhale. ",
    audioSegment: 'Jo_Break_027',
  },
  {
    coach: coaches.maria,
    text: 'Drink some water and give your eyes a break before refocusing on your next Task.',
    audioSegment: 'NotionMaria_Break_028',
  },
  {
    coach: coaches.cassidy,
    text: "Hey, now's the time if you wanna grab some water and a snack before your next meeting.  I have nachos.",
    audioSegment: 'CW_Break_030',
  },
  {
    coach: coaches.arianna,
    text: 'I think you have more than earned a break, go take one.',
    audioSegment: 'Arianna_Play_009',
  },
  {
    coach: coaches.lenny,
    text: "Now is a great time to take a few deep breaths.  Start the breathing exercise and let's get centered together.",
    audioSegment: 'Len_Breath_027',
  },
  {
    coach: coaches.august,
    text: 'Give your eyes a rest.',
    audioSegment: 'August_Breath_003',
  },
  {
    coach: coaches.lenny,
    text: 'Your break is coming up.  Begin to come out of Flow.',
    audioSegment: 'Len_Break_017',
  },
  {
    coach: coaches.ben,
    text: 'Take a minute to focus your eyes on the far-off galaxies away from your screen.',
    audioSegment: 'Astro_Breath_002',
  },
]
