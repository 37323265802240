import AsanaLogo from '@centered/components/dist/icons/AsanaLogo'
import DiscordLogo from '@centered/components/dist/icons/DiscordLogo'
import GoogleCalendarLogo from '@centered/components/dist/icons/GoogleCalendarLogo'
import LinearLogo from '@centered/components/dist/icons/LinearLogo'
import SlackLogo from '@centered/components/dist/icons/SlackLogo'
import SpotifyLogo from '@centered/components/dist/icons/SpotifyLogo'
import TodoistLogo from '@centered/components/dist/icons/TodoistLogo'
import Wrapper from '../shared/Wrapper'
import TryCenteredButton from './TryCenteredButton'

export default function AvailableIntegrations(): JSX.Element {
  return (
    <Wrapper className="">
      <div className="mb-14">
        <header className="text-center mb-20">
          <h2 className="font-bold text-2xl md:text-4xl mb-4">
            But I already have a workflow...
          </h2>
          <p className="text-white text-opacity-80 text-base md:text-2xl">
            Yes, we integrate with a ton of your favorites.
          </p>
        </header>

        <div className="flex gap-6 flex-wrap justify-center mb-10 text-4xl md:text-5xl">
          <SpotifyLogo />
          <GoogleCalendarLogo />
          <SlackLogo />
          <TodoistLogo />
          <LinearLogo />
          <DiscordLogo />
          <AsanaLogo />
        </div>
      </div>

      <div className="flex justify-center mt-32">
        <TryCenteredButton
          source="v2CTA3AvailableIntegrations"
          noGoogleAuthSource="v2CTA3AvailableIntegrationsNonGoogle"
        />
      </div>
    </Wrapper>
  )
}
