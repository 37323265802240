import { StaticImageData } from 'next/image'
import AlexAvatar from './assets/coaches/alex.jpg'
import AnaAvatar from './assets/coaches/ana.jpg'
import AriannaAvatar from './assets/coaches/arianna.jpg'
import AugustAvatar from './assets/coaches/august.jpg'
import BenAvatar from './assets/coaches/ben.jpg'
import BrianAvatar from './assets/coaches/brian.jpg'
import CassidyAvatar from './assets/coaches/cassidy.jpg'
import ChrisAvatar from './assets/coaches/chris.jpg'
import EddieAvatar from './assets/coaches/eddie.jpg'
import JasonAvatar from './assets/coaches/jason.jpg'
import JeromeAvatar from './assets/coaches/jerome.jpg'
import JoAvatar from './assets/coaches/jo.jpg'
import KennyAvatar from './assets/coaches/kenny.jpg'
import KentAvatar from './assets/coaches/kent.jpg'
import LennyAvatar from './assets/coaches/lenny.jpg'
import MariaAvatar from './assets/coaches/maria.jpg'
import NicolasAvatar from './assets/coaches/nicolas.jpg'
import NirAvatar from './assets/coaches/nir.jpg'
import NoahAvatar from './assets/coaches/noah.jpg'
import ReeceAvatar from './assets/coaches/reece.jpg'

export type CoachView = {
  name: string
  avatar: StaticImageData
}

export const coaches = {
  cassidy: {
    name: 'Cassidy Williams',
    avatar: CassidyAvatar,
  },
  noah: {
    name: 'Noah',
    avatar: NoahAvatar,
  },
  arianna: {
    name: 'Arianna Bradford',
    avatar: AriannaAvatar,
  },
  august: {
    name: 'August Bradley',
    avatar: AugustAvatar,
  },
  nir: {
    name: 'Nir Eyal',
    avatar: NirAvatar,
  },
  nicolas: {
    name: 'Nicolas Carlo',
    avatar: NicolasAvatar,
  },
  kenny: {
    name: 'Kenny Eze',
    avatar: KennyAvatar,
  },
  chris: {
    name: 'Chris Messina',
    avatar: ChrisAvatar,
  },
  alex: {
    name: 'Alex Riviere',
    avatar: AlexAvatar,
  },
  eddie: {
    name: 'Eddie Jaoude',
    avatar: EddieAvatar,
  },
  kent: {
    name: 'Kent C. Dodds',
    avatar: KentAvatar,
  },
  maria: {
    name: 'Maria Aldrey',
    avatar: MariaAvatar,
  },
  ana: {
    name: 'Ana Avila',
    avatar: AnaAvatar,
  },
  lenny: {
    name: 'Lenny Rachitsky',
    avatar: LennyAvatar,
  },
  ben: {
    name: 'Ben Holmes',
    avatar: BenAvatar,
  },
  jo: {
    name: 'The Bougie Bruja',
    avatar: JoAvatar,
  },
  reece: {
    name: 'Reece Palmer',
    avatar: ReeceAvatar,
  },
  jason: {
    name: 'Jason Lengstorf',
    avatar: JasonAvatar,
  },
  brian: {
    name: 'Brian McCullough',
    avatar: BrianAvatar,
  },
  jerome: {
    name: 'Jerome Hardaway',
    avatar: JeromeAvatar,
  },
} as const

export const decorateAudioUrl = (url: string): string =>
  url.startsWith('https://')
    ? url
    : `https://d10gj7r7js3e4x.cloudfront.net/${url}.mp3`
