import Image from 'next/image'
import Wrapper from '../shared/Wrapper'
import BuddySessionImage from './assets/buddy-session.jpg'

export default function BuddySessions(): JSX.Element {
  return (
    <Wrapper>
      <div className="max-w-5xl mx-auto">
        <header className="mb-20">
          <h2 className="font-bold text-center text-2xl md:text-4xl md:leading-tight mb-4">
            I procrastinate. &nbsp; (truth)
            <br />
            But working with a friend helps me to get started...
          </h2>
          <p className="text-base md:text-2xl text-center">
            You’re not alone. &nbsp;Invite a friend to a Buddy Session or we can
            match you with someone.
          </p>
        </header>

        <Image
          src={BuddySessionImage}
          alt="two knowledge workers doing a body doubling session"
          className="mx-auto rounded-xl shadow"
        />
      </div>
    </Wrapper>
  )
}
