import Image from 'next/image'
import Wrapper from '../shared/Wrapper'
import SampleCoachMessage from './SampleCoachMessage'
import PhoneDistractingImage from './assets/phone-distraction.jpg'
import { coaches } from './coaches'

export default function PhoneDistraction(): JSX.Element {
  return (
    <Wrapper>
      <div className="flex flex-col-reverse md:flex-row md:items-center gap-20">
        <div className="relative ml-4">
          <div className="absolute z-10 -left-4 -top-8">
            <SampleCoachMessage messages={messages} module="phone" />
          </div>

          <Image
            src={PhoneDistractingImage}
            alt="focus app stopping a cell phone distraction"
            className="rounded-xl"
            width={480}
            height={440}
            style={{
              borderRadius: 32,
            }}
          />
        </div>

        <div className="">
          <h2 className="text-2xl md:text-4xl font-bold leading-normal mb-10">
            My phone is crazy <br className="hidden lg:inline-block" />{' '}
            distracting...
          </h2>

          <p className="text-xl md:text-2xl">We'll lock that for you.</p>
        </div>
      </div>
    </Wrapper>
  )
}

const messages = [
  {
    coach: coaches.lenny,
    text: 'Stay mindful...focused...and in Flow',
    audioSegment: 'Len_DesktopDistract_014',
  },
  {
    coach: coaches.alex,
    text: 'Woah woah woah woah woah...Not so fast, my friend. You have got a few more minutes to focus.',
    audioSegment: 'AR_DesktopDistract_036',
  },
  {
    coach: coaches.chris,
    text: "Umm...What's going on over there?  Where did you go?",
    audioSegment: 'CM_DesktopDistract_022',
  },
  {
    coach: coaches.reece,
    text: "Hey you. It's your computer. Where do you think you're going?",
    audioSegment: 'RP_PhoneDistract_010',
  },
  {
    coach: coaches.nir,
    text: 'Hey, you!  Your future called - it wants your attention back.',
    audioSegment: 'NIR_PhoneDistract_021',
  },
  {
    coach: coaches.ben,
    text: "Hey, where do you think your wandering off to?  That's uncharted territory.",
    audioSegment: 'Astro_DesktopDistract_038',
  },
  {
    coach: coaches.kenny,
    text: "uh uh uh‚ are you sure you want to break your Flow?  You're doing so well!",
    audioSegment: 'Kenny_PhoneDistract_012',
  },
  {
    coach: coaches.chris,
    text: 'Your phone is not your boss.  Stop treating it like it owns you.',
    audioSegment: 'CM_PhoneDistract_019',
  },
  {
    coach: coaches.kent,
    text: "What if you didn't check your phone right now?",
    audioSegment: 'RMX_PhoneDistract_006',
  },
  {
    coach: coaches.august,
    text: 'Why let a cellphone destroy your Flow?',
    audioSegment: 'August_PhoneDistract_004',
  },
  {
    coach: coaches.jo,
    text: 'I hope that phone is part of your Task.',
    audioSegment: 'Jo_PhoneDistract_011',
  },
  {
    coach: coaches.maria,
    text: 'Stay...intentional.',
    audioSegment: 'NotionMaria_PhoneDistract_017',
  },
  {
    coach: coaches.jason,
    text: 'Are you using that phone for good or for evil?',
    audioSegment: 'JasonLangsdorf_PhoneDistract_008',
  },
  {
    coach: coaches.cassidy,
    text: "Don't check your stocks, check your clocks.  That's right you're running out of time, get back to work. ",
    audioSegment: 'CW_PhoneDistract_023',
  },
  {
    coach: coaches.arianna,
    text: "Every time you touch your phone a kitten cries.  You don't want any kittens to cry do you?",
    audioSegment: 'Arianna_PhoneDistract_007',
  },
]
