import { StaticImageData } from 'next/image'

import KentAvatar from './assets/kent-quote-avatar.jpg'
import KentBg from './assets/kent-quote-bg.jpg'
import LennyAvatar from './assets/lenny-quote-avatar.jpg'
import LennyBg from './assets/lenny-quote-bg.jpg'
import MarshallAvatar from './assets/marshall-quote-avatar.jpg'
import MarshallBg from './assets/marshall-quote-bg.jpg'
import MartinAvatar from './assets/martin-quote-avatar.jpg'
import MartinBg from './assets/martin-quote-bg.jpg'
import WagnerAvatar from './assets/wagner-quote-avatar.jpg'
import WagnerBg from './assets/wagner-quote-bg.jpg'

export type Quote = {
  avatar: StaticImageData
  background: StaticImageData
  name: string
  handle: string
  quote: JSX.Element
}

export const quotes = {
  lenny: {
    avatar: LennyAvatar,
    background: LennyBg,
    name: 'Lenny Rachitsky',
    handle: '@lennysan',
    quote: (
      <>
        A game changer! <br /> I got more done in the last 3
        hours than I did all of yesterday!
      </>
    ),
  },
  martin: {
    avatar: MartinAvatar,
    background: MartinBg,
    name: 'Martin Slaney',
    handle: '@MartinSlaney',
    quote: (
      <>
        Product Of The Year - by a mile.
        <br />A new age of productivity has begun.
      </>
    ),
  },

  marshall: {
    avatar: MarshallAvatar,
    background: MarshallBg,
    name: 'Marshall Kirkpatrick',
    handle: '@marshallk',
    quote: (
      <>
        Wow this is the coolest productivity app <br />
        I’ve tried in a long, long time. <br />
        It’s like a dream come true.
      </>
    ),
  },

  wagner: {
    avatar: WagnerAvatar,
    background: WagnerBg,
    name: 'Wagner',
    handle: '@jsemotionz',
    quote: (
      <>
        This app really gets every feature you need to focus /
        get in the flow in one place and it does it very well.
        &nbsp;The social aspect and also the human voice are huge features, I
        love it!
      </>
    ),
  },

  kent: {
    avatar: KentAvatar,
    background: KentBg,
    name: 'Kent C. Dodds',
    handle: '@kentcdodds',
    quote: (
      <>
        Just so much to get done for the @remix_run launch on Monday. <br /> I
        could never have gotten all this done without it. <br />
        This thing is amazing.
      </>
    ),
  },
} as const
