import Wrapper from '../shared/Wrapper'
import TryCenteredButton from './TryCenteredButton'

export default function FewMoreThings(): JSX.Element {
  return (
    <Wrapper>
      <div className="">
        <h2 className="font-bold text-center text-2xl md:text-4xl mb-10">
          A few more things before I...
        </h2>

        <div className="flex justify-center">
          <TryCenteredButton
            source="v2CTA2AFewMoreThings"
            noGoogleAuthSource="v2CTA2FewMoreThingsNonGoogle"
          />
        </div>
      </div>
    </Wrapper>
  )
}
