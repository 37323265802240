import CheckMarkIcon from '@centered/components/dist/icons/CheckMarkIcon'
import Wrapper from '../shared/Wrapper'
import SampleCoachMessage from './SampleCoachMessage'
import { coaches } from './coaches'

export default function TaskModule(): JSX.Element {
  return (
    <Wrapper>
      <div className="flex flex-col md:flex-row gap-20 md:items-center justify-between mr-4">
        <div className="">
          <h2 className="text-2xl md:text-4xl font-bold leading-normal mb-10">
            I need help keeping <br /> track of my tasks...
          </h2>

          <p className="text-lg md:text-2xl">Yep.</p>
        </div>

        <Tasks />
      </div>
    </Wrapper>
  )
}

const tasks: {
  name: string
  tag?: string
  isCompleted?: boolean
}[] = [
  {
    name: 'Inbox Zero',
    isCompleted: true,
  },
  {
    name: 'Review PR-1762',
    tag: 'Work',
  },
  {
    name: 'Design new Styleguide',
    tag: 'SideHustle',
  },
]

function Tasks(): JSX.Element {
  return (
    <div
      style={{
        background: 'linear-gradient(116.92deg, #272727 0%, #1E1E1E 100%)',
      }}
      className="p-4 md:p-8 pt-6 md:pt-14 space-y-6 rounded-xl w-full max-w-sm md:max-w-md relative"
    >
      <div className="absolute -right-4 -top-8">
        <SampleCoachMessage messages={messages} module="tasks" />
      </div>

      {tasks.map((task, i) => (
        <div
          style={{
            backgroundColor: '#333',
          }}
          key={i}
          className="flex gap-4 p-4 rounded-xl items-center"
        >
          <div className="relative pointer-events-none">
            <CheckMarkIcon circled solid={task.isCompleted} />
          </div>

          <div>
            <div
              className={`text-sm md:text-base ${
                task.isCompleted ? 'line-through text-v2-accent-lighter' : ''
              }`}
            >
              {task.name}
            </div>

            {task.tag && (
              <p className="text-sm md:text-base bg-white bg-opacity-20 rounded inline-block px-2 mt-1">
                #{task.tag}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

const messages = [
  {
    coach: coaches.arianna,
    text: "Come back, come baaacckkk.  That's your Tasks calling you back.",
    audioSegment: 'Arianna_DesktopDistract_027',
  },
  {
    coach: coaches.nir,
    text: 'Well done clearing out your inbox!',
    audioSegment: 'NIR_EOS_020',
  },
  {
    coach: coaches.kenny,
    text: "Look at how well it's coming together!  Congrats on that writing session. ",
    audioSegment: 'Kenny_EOS_018',
  },
  {
    coach: coaches.brian,
    text: "Are you ready for a great Flow Session?  Let's go.",
    audioSegment: 'BM_Intro_015',
  },
  {
    coach: coaches.jerome,
    text: 'Ready to dive in?',
    audioSegment: 'JH_Intro_002',
  },
  {
    coach: coaches.alex,
    text: 'Way to go on your latest design session.',
    audioSegment: 'AR_Play_033',
  },
  {
    coach: coaches.jo,
    text: "Alright, love, you are so productive.   You've got this. ",
    audioSegment: 'Jo_DesktopDistract_040',
  },
  {
    coach: coaches.maria,
    text: "Let's wrap up this Task so you have time to prep for your next meeting.",
    audioSegment: 'NotionMaria_MeetingNotify_004',
  },
  {
    coach: coaches.lenny,
    text: "Look at how well that's coming together!  Congrats on that writing session. ",
    audioSegment: 'Len_EOS_018',
  },
  {
    coach: coaches.cassidy,
    text: 'Oh, VS Code, eh?  We have a nerd on our hands!',
    audioSegment: 'CW_Encouragement_027',
  },
  {
    coach: coaches.kent,
    text: 'Well done, now feel that sense of accomplishment.',
    audioSegment: 'RMX_EOS_007',
  },
]
