import SpotifyLogo from '@centered/components/dist/icons/SpotifyLogo'
import Image from 'next/image'
import Wrapper from '../shared/Wrapper'
import AmbientAtmosphereCoverArt from './assets/ambient-atmosphere-cover-art.jpg'
import SonicCaffeineCoverArt from './assets/sonic-caffeine-album-art.jpg'
import StarWarsCoverArt from './assets/starwars-album-cover-art.jpg'

export default function MusicModule(): JSX.Element {
  return (
    <Wrapper>
      <div className="flex flex-col md:flex-row gap-10 items-center justify-between">
        <div className="">
          <h2 className="text-2xl md:text-4xl font-bold leading-normal mb-10">
            Slow jams are my <br />
            flow jams...
          </h2>

          <p className="text-xl md:text-2xl">
            150+ hours of custom music
            <br />
            <br />
            19 Playlists - upbeat, downbeat, coffeeshop, binaural
            <br />
            <br />+ yeah, hook up your Spotify, too
          </p>
        </div>

        <Playlists />
      </div>
    </Wrapper>
  )
}

const playlists = [
  {
    name: 'Ambient Atmosphere',
    image: AmbientAtmosphereCoverArt,
    description:
      'Mid-tempo non-vocal melodies to calm the mind and help you focus.',
    isSpotify: false,
  },
  {
    name: 'Sonic Caffeine',
    image: SonicCaffeineCoverArt,
    description:
      'A vibrant collection of tracks to energize and motivate you to stay on track.',
    isSpotify: false,
  },
  {
    name: 'Star Wars Lo-Fi Hip Hop',
    image: StarWarsCoverArt,
    description:
      'What real Mandalorians listen to on the way back to the Outer Rim.',
    isSpotify: true,
  },
] as const

function Playlists(): JSX.Element {
  return (
    <div
      style={{
        background: 'linear-gradient(116.92deg, #272727 0%, #1E1E1E 100%)',
      }}
      className="p-4 md:p-6 space-y-4 md:space-y-6 rounded-xl max-w-md lg:max-w-lg"
    >
      {playlists.map((playlist, i) => (
        <div
          style={{
            backgroundColor: '#333',
          }}
          key={i}
          className="flex gap-4 p-4 rounded-xl items-center"
        >
          <div className="relative flex-shrink-0">
            <Image
              src={playlist.image}
              alt={playlist.name}
              className="rounded-md w-10 h-10 md:w-20 md:h-20"
            />
            {playlist.isSpotify && (
              <div className="absolute -right-2 -bottom-2 text-xl">
                <SpotifyLogo />
              </div>
            )}
          </div>

          <div className="text-sm">
            <div>{playlist.name}</div>

            <p className="text-white text-opacity-60">{playlist.description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}
