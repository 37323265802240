import Image from 'next/image'
import Wrapper from '../shared/Wrapper'
import Avatar from './assets/ana-tweet-avatar.jpg'

export default function Difference(): JSX.Element {
  return (
    <Wrapper>
      <div className="flex flex-col-reverse md:flex-row-reverse gap-10 items-center justify-between">
        <div className="flex-1 max-w-xl">
          <h2 className="text-2xl md:text-4xl font-bold leading-normal mb-10">
            So it's great music, a timer and...?
          </h2>

          <p className="text-base md:text-2xl">
            We started with scientifically-designed music to help you focus...
            <br />
            <br />
            and we added a pomodoro timer...
            <br />
            <br />
            and then gave you a bunch more tools for free.
          </p>
        </div>

        <div
          style={{
            borderRadius: 48,
          }}
          className="bg-white text-black p-8 w-full max-w-sm lg:max-w-lg"
        >
          <header className="flex gap-4 mb-6 items-center">
            <Image
              src={Avatar}
              alt="Ana's avatar"
              width={58}
              height={58}
              className="rounded-full"
            />

            <div className="text-sm md:text-base">
              <p className="font-bold">Anna McDougall (She/Her) 🏳️‍🌈</p>
              <p className="text-black text-opacity-60">@AnnaJMcDougall</p>
            </div>
          </header>

          <div className="lg:text-lg">
            <p>
              BONUS TIP: I found a FREE tool which does a
              lot of these things for you!
            </p>

            <br />

            <p>They give you:</p>

            <ul className="list-disc pl-8">
              {[
                'A coach to encourage you',
                'Pomodoro timeboxing',
                'A variety of music choices',
                'Muted notifications',
                'Nudges when you get distracted',
                'Breathing exercises',
                'a LEADERBOARD',
              ].map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
