import { trackEvent } from '@centered/analytics'
import ChevronIcon from '@centered/components/dist/icons/ChevronIcon'
import PauseIcon from '@centered/components/dist/icons/PauseIcon'
import PlayIcon from '@centered/components/dist/icons/PlayIcon'
import { FIREBASE_CLOUD_FUNCTIONS_URL } from '@centered/firebase'
import { Group } from '@centered/types'
import { AnimateSharedLayout, motion } from 'framer-motion'
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import Wrapper from '../shared/Wrapper'

interface AICoachesProps {
  featuredGroups: Group[]
}

export default function AICoaches({
  featuredGroups,
}: AICoachesProps): JSX.Element {
  const [cardDisplayOffset, setCardDisplayOffset] = useState(1)
  const [chosenCardId, setChosenCardId] = useState(featuredGroups[1].id)

  const cards = [
    getCardAtIdx(cardDisplayOffset - 1, featuredGroups),
    getCardAtIdx(cardDisplayOffset, featuredGroups),
    getCardAtIdx(cardDisplayOffset + 1, featuredGroups),
    getCardAtIdx(cardDisplayOffset + 2, featuredGroups),
    getCardAtIdx(cardDisplayOffset + 3, featuredGroups),
    getCardAtIdx(cardDisplayOffset + 4, featuredGroups),
    getCardAtIdx(cardDisplayOffset + 5, featuredGroups),
    getCardAtIdx(cardDisplayOffset + 6, featuredGroups),
    getCardAtIdx(cardDisplayOffset + 7, featuredGroups),
  ]

  const chosenCard = featuredGroups.find((group) => group.id === chosenCardId)

  return (
    <div className="w-full">
      <Wrapper>
        <div className="">
          <header className="mb-20">
            <h2 className="font-bold text-2xl md:text-4xl text-center mb-4">
              Wait - You have AI Coaches, too?
            </h2>
            <p className="text-base md:text-xl text-center">
              24/7 they'll nudge you back on track when you goof off. &nbsp;Not that you goof
              off, but...well...
            </p>
          </header>

          {chosenCard && (
            <div className="hidden md:block w-f">
              <GroupCard group={chosenCard} />
            </div>
          )}

          <div className="w-full justify-center items-center hidden md:flex pb-28">
            <div
              onClick={() => {
                let newIdx = cardDisplayOffset - 1
                if (newIdx < 0) {
                  newIdx = featuredGroups.length - 1
                }
                setCardDisplayOffset(newIdx)
              }}
              className="h-full text-white text-3xl cursor-pointer"
            >
              <ChevronIcon direction="left" />
            </div>

            <AnimateSharedLayout>
              {cards.map((card, i) => {
                if (!card) {
                  return <></>
                }

                return (
                  <motion.div layout key={card.id}>
                    <div
                      onClick={() => {
                        setChosenCardId(card.id)
                      }}
                      className={`cursor-pointer relative transition-all hover:opacity-80 border-2 rounded-lg ${
                        i === 0 || i === cards.length - 1 ? 'hidden' : ''
                      } ${
                        i === 1 || i === cards.length - 2
                          ? 'hidden md:block'
                          : ''
                      }`}
                      style={{
                        transform: `scale(0.85)`,
                        width: 112,
                        height: 160,
                        overflow: 'hidden',
                        borderColor:
                          chosenCardId === card.id ? '#4294F7' : 'transparent',
                        opacity: chosenCardId === card.id ? 1 : 0.5,
                      }}
                    >
                      <Image
                        src={
                          card.featureUrl ??
                          card.coverUrl ??
                          `${FIREBASE_CLOUD_FUNCTIONS_URL}/randomPhoto?collectionId=j1fwrKAGDIg`
                        }
                        alt={card.name}
                        fill
                        className="rounded-lg object-cover border-2 border-transparent"
                      />
                    </div>
                  </motion.div>
                )
              })}
            </AnimateSharedLayout>

            <div
              onClick={() => {
                let newIdx = cardDisplayOffset + 1
                if (newIdx >= featuredGroups.length) {
                  newIdx = 0
                }
                setCardDisplayOffset(newIdx)
              }}
              className="h-full text-white text-3xl cursor-pointer"
            >
              <ChevronIcon direction="right" />
            </div>
          </div>
        </div>
      </Wrapper>

      <GroupsList groups={featuredGroups} />
    </div>
  )
}

function getCardAtIdx<T>(idx: number, items: T[]): T {
  if (idx < 0) {
    const modifiedIdx = items.length + idx
    return items[modifiedIdx]
  }

  if (idx >= items.length) {
    return items[idx - items.length]
  }

  return items[idx]
}

interface GroupCardProps {
  group: Group
  variant?: 'default' | 'small'
}

function GroupCard({
  group,
  variant = 'default',
}: GroupCardProps): JSX.Element {
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [audioProgress, setAudioProgress] = useState(0)
  const animationRef = useRef<number>()

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
      }

      setIsPlaying(false)
      setAudioProgress(0)
    }
  }, [group])

  return (
    <>
      <audio
        ref={audioRef}
        src={
          group?.introAudioUrl ??
          'https://firebasestorage.googleapis.com/v0/b/centered-vo-tracks/o/VG_ID_007.mp3?alt=media&token=de351277-556a-40cf-9df3-cb295ecf0b8c'
        }
        onPlay={() => {
          setIsPlaying(true)

          function updateProgress() {
            if (audioRef.current) {
              const audio = audioRef.current
              const progress = audio.currentTime / audio.duration

              setAudioProgress(progress)
            }

            animationRef.current = requestAnimationFrame(updateProgress)
          }

          animationRef.current = requestAnimationFrame(updateProgress)
        }}
        onEnded={() => {
          setAudioProgress(1)
          setIsPlaying(false)

          if (animationRef.current) {
            cancelAnimationFrame(animationRef.current)
          }

          setTimeout(() => {
            setAudioProgress(0)
          }, 500)
        }}
        onPause={() => setIsPlaying(false)}
      />

      <div
        className={`mx-auto w-full max-w-3xl rounded-3xl bg-white bg-opacity-20 flex ${
          variant === 'small' ? 'flex-col' : 'flex-row'
        } items-center gap-4 mb-10`}
      >
        <div className="flex-shrink-0 flex-grow-0 relative">
          <Image
            src={
              group?.featureUrl ??
              group?.coverUrl ??
              `${FIREBASE_CLOUD_FUNCTIONS_URL}/randomPhoto?collectionId=j1fwrKAGDIg`
            }
            alt={group?.name ?? 'A random photo'}
            width={246}
            height={352}
            className="object-cover object-center rounded-3xl"
            style={{
              width: 246,
              height: 352,
            }}
          />
        </div>

        <div className={`${variant === 'small' ? 'p-4' : ''}`}>
          {group?.coach && (
            <header className="flex items-center gap-2 md:gap-4 mb-4">
              <Image
                src={group.coach.avatarUrl ?? ''}
                alt={group?.coach.name}
                width={50}
                height={50}
                className="object-cover object-center rounded-full w-8 h-8 md:w-12 md:h-12"
              />

              <div>
                <p className="text-white text-opacity-60 text-xs md:text-base">
                  Your A.I. Coach
                </p>
                <h2 className="text-sm md:text-lg">{group.coach.name}</h2>
              </div>
            </header>
          )}

          <p
            style={{
              wordBreak: 'break-word',
            }}
            className="mb-4 text-white text-opacity-60 pr-4"
          >
            {group?.description}
          </p>

          <div
            onClick={() => {
              trackEvent('Listened to home page coach intro', {
                groupId: group.id,
              })

              if (audioRef.current) {
                if (isPlaying) {
                  audioRef.current.pause()
                } else {
                  audioRef.current.play()
                }
              }
            }}
            className="relative bg-white bg-opacity-20 cursor-pointer hover:bg-opacity-40 transition-colors rounded-md px-4 py-2 inline-flex items-center gap-4"
          >
            <div
              className="rounded-md bg-black bg-opacity-20 absolute top-0 bottom-0 left-0"
              style={{
                width: `${audioProgress * 100}%`,
              }}
            />

            {isPlaying ? (
              <PauseIcon className="relative z-10" />
            ) : (
              <PlayIcon solid className="relative z-10" />
            )}

            <div className="relative z-10">Hear your Coach</div>
          </div>
        </div>
      </div>
    </>
  )
}

interface GroupsListProps {
  groups: Group[]
}

function GroupsList({ groups }: GroupsListProps): JSX.Element {
  return (
    <div className="w-full md:hidden overflow-x-scroll flex gap-6 px-8">
      {groups.map((group) => (
        <div
          key={group.id}
          style={{
            width: 246,
          }}
          className="flex-shrink-0 snap-center w-full"
        >
          <GroupCard group={group} variant="small" />
        </div>
      ))}
    </div>
  )
}
